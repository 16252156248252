import { Order } from 'utils/types';

export const trackJustUno = (eventName: string, ...args: any): void => {
  window.juapp =
    window.juapp ||
    function () {
      (window.juapp.q = window.juapp.q || []).push(arguments);
    };

  window.juapp(eventName, ...args);
};

function buildJustUnoOrder(spree_order: Order) {
  return {
    total: spree_order.total,
    subtotal: spree_order.amount,
    tax: 0.0,
    shipping: spree_order.shipping,
    currency: 'USD',
  };
}

function buildJustUnoLineItem(spree_line_item: Order['line_items'][0]) {
  return {
    productid: spree_line_item.product_id,
    variationid: spree_line_item.variant_id,
    quantity: spree_line_item.quantity,
    price: spree_line_item.total,
  };
}

export const trackJustUnoConversion = (spreeOrder: Order) => {
  var juo = buildJustUnoOrder(spreeOrder);
  trackJustUno('order', spreeOrder.id, juo);

  spreeOrder.line_items.forEach(function (lineItem) {
    var li = buildJustUnoLineItem(lineItem);
    trackJustUno('orderItem', li);
  });
};
