import React from 'react';
import { Script } from 'gatsby';
import { QueryClientProvider } from '@tanstack/react-query';
import { UserContextProvider } from './src/utils/hooks/useUserContext/context';
import { MembershipCheckoutProvider } from './src/components/MembershipUtils/MembershipCheckoutContext/MembershipCheckoutContext';
import { queryClient } from './src/utils/api/instances';

import '@everlywell/leaves/lib/style.css';

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <MembershipCheckoutProvider>
          {element}
          <Script
            src="https://apis.google.com/js/platform.js?onload=renderOptIn"
            strategy="idle"
            async
            defer
          />
          <Script
            src="https://refer.everlywell.com/core.js"
            strategy="idle"
            async
            defer
          />
          <Script
            src="https://unpkg.com/@phosphor-icons/web@2.1.1"
            strategy="idle"
            async
            defer
          />
          <Script
            id="justuno"
            dangerouslySetInnerHTML={{
              __html: `
              window.appRoot = '';
              window.ju_num = '${process.env.JUSTUNO_ACCOUNT_ID}';
              window.asset_host = '//cdn.jst.ai/';
              (function (i, s, o, g, r, a, m) {
                i[r] = i[r] || function () {
                  (i[r].q = i[r].q || []).push(arguments);
                };
                (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
              })(window, document, 'script', window.asset_host + 'vck.js', 'juapp');
            `,
            }}
            strategy="idle"
            async
            defer
          />
        </MembershipCheckoutProvider>
      </UserContextProvider>
    </QueryClientProvider>
  );
};
