/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import CryptoES from 'crypto-es';
import { formatDistance } from 'date-fns';
import React, { ReactElement, RefObject, useEffect, useRef } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import {
  ContentfulReview,
  IterableProduct,
  MembershipTypeOptions,
  Product,
  ProductDataType,
  ProductsBySlugType,
  PromoDataResult,
  ProductVariants,
  Reviews,
  Variant,
  PageProductPromo,
} from 'utils/types';
import { MembershipCategory } from 'utils/types/membershipSkus';

import { getValueByKey } from './helpers/membershipSkuHelper';
import { tracker } from './trackers';

const logError = (error: string, context: object | undefined): void => {
  datadogLogs.logger.error(error, context);
};

/*
 * https://www.toptal.com/designers/htmlarrows/symbols/
 */
export const specialCharacters = [
  '®', // RegisteredTrademark
  '™', // Trademark
  '©', // Copymark
  '&reg;', // RegisteredTrademarkEntity
  '&trade;', // TrademarkEntity
  '&copy;', // CopymarkEntity
];

function addSuperscript(content: string): ReactElement {
  const regex = new RegExp(`(${specialCharacters.join('|')})`, 'g');

  if (!regex.test(content)) {
    return <>{content}</>;
  }

  const parts = content.split(regex).map((part, index) =>
    specialCharacters.includes(part) ? (
      <sup style={{ verticalAlign: 'super', fontSize: '14px' }} key={index}>
        {part}
      </sup>
    ) : (
      part
    ),
  );

  return <>{parts}</>;
}

const numericDate = (date: string): string =>
  new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  });

const timeAgo = (date: string): string => {
  if (!date) return '';
  return (
    date &&
    formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    })
  );
};

const formatPrice = (price: number): string => {
  if (price) return price.toFixed(2);
  return '0.00';
};

/**
 * Gatsby helper function to check if window is defined
 * https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
 *
 * It returns true if window is present, and false if not
 */
const isBrowser = (): boolean => typeof window !== 'undefined';

/**
 * Updated algorithm per this article: https://www.linkedin.com/pulse/how-protect-sensitive-user-data-sha-256-hashing-cryptojs-manish-k-b/
 * @param rawMessage the string to hash
 * @returns
 */
const hashSha256 = (rawMessage: string | undefined): string | undefined => {
  if (typeof rawMessage === 'string') {
    return CryptoES.SHA256(rawMessage).toString();
  }
  return undefined;
};

const scrollToId = (id: string): null | void => {
  if (!isBrowser()) return null;
  const headerHeight = 86;
  const el = document.getElementById(id);

  if (!el) return;

  // add polyfill to support smooth scrolling
  smoothscroll.polyfill();

  // scroll to element and make room for header
  window.scrollBy({
    behavior: 'smooth',
    top: el.offsetTop - window.pageYOffset - headerHeight,
  });
};

const useClickOff = (onClose: (e: any) => void) => {
  const wrapperEl = useRef<HTMLDivElement>(null);

  // Solves the issue of tapping away in iOS to trigger the click event on the document
  useEffect(() => {
    document.body.style.cursor = 'pointer';

    return () => {
      document.body.style.cursor = 'default';
    };
  }, []);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!wrapperEl.current || !e.target) {
        return;
      }

      if (!wrapperEl.current.contains(e.target as Node)) {
        onClose(e);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [onClose]);

  return wrapperEl;
};

/**
 * Currently an open bug with body-scroll-lock on iPhone specifically iOS 12.x
 * if you touch at the bottom of the page when the browser controls ( bottom bar ) are hidden
 * you are still able to scroll the background
 *
 * when the controls down at the bottom are visible, the body-scroll-lock will work as intended
 *
 * the touchmove event isn't triggered at all down in the area of the bottom toolbar.
 * bug filed: https://bugs.webkit.org/show_bug.cgi?id=189586
 * github issue: https://github.com/willmcpo/body-scroll-lock/issues/82
 * */

const useDisableBodyScroll = (
  ref?: RefObject<HTMLDivElement>,
): RefObject<HTMLDivElement> => {
  let currRef = useRef<HTMLDivElement>(null);
  if (ref) currRef = ref;
  const wrapperEl = currRef;

  useEffect(() => {
    const node = wrapperEl.current;

    if (node) {
      disableBodyScroll(node, {
        reserveScrollBarGap: true,
      });
    }
    return () => {
      if (node) {
        enableBodyScroll(node);
      }
    };
  }, [wrapperEl]);

  return wrapperEl;
};

const capitalize = (str: string): string => str[0].toUpperCase() + str.slice(1);

const generateKey: (length?: number) => string = (length = 8) =>
  Math.random().toString(16).substr(2, length);

/*
 * Filter products by category
 * Check if the main category or one of categories matches a given category
 * params: aryOfProducts - array of products to filter
 *         category - category to filter by
 * return: array of products that match the category
 * */
const filterUniqueByCategory = (
  aryOfProducts: ProductDataType[],
  category: string,
): ProductDataType[] =>
  aryOfProducts.filter(
    (product) =>
      product.categories?.includes(category) || product.category === category,
  );

const toKebabCase = (str: string): string =>
  str
    .trim()
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();

const trackIterable = (
  eventId: string | number,
  product: IterableProduct,
): void => {
  try {
    if (
      isBrowser() &&
      //hacky temporary solution until we fix Iterable events locally
      window.location.host !== 'localhost:8080'
    ) {
      tracker.trackPdpPageView({
        eventId: eventId.toString(),
        productId: product.id,
        productName: product.name,
        productPrice: product.price,
      });
    }
  } catch (err) {
    logError((err as Error).message, {
      method: 'trackIterable',
    });
  }
};

const findAllByKey = (obj: any, keyToFind: string): any => {
  try {
    return getValueByKey(obj, keyToFind);
  } catch (err) {
    logError((err as Error).message, {
      method: 'findAllByKey',
    });
  }
};

const getSkuKey = (
  item: Product | Variant,
  isVariant = false,
): string | void => {
  try {
    const memberShipTypeSubstr = item?.sku?.includes(
      MembershipTypeOptions.control,
    )
      ? 'control-membership-'
      : 'sti-membership-';
    const substr = !isVariant ? '-membership' : memberShipTypeSubstr;
    return item?.sku?.replace(substr, '') ?? '';
  } catch (err) {
    logError((err as Error).message, {
      method: 'getSkuKey',
    });
  }
};

const getVariantId = (memberShipCategory: MembershipCategory, sku: string) => {
  try {
    const variant = findAllByKey(memberShipCategory, sku || '')[0] ?? '';
    const variantId = variant?.variantInfo
      ? variant?.variantInfo[sku]
      : variant;

    if (!variantId) throw new Error('Variant not found');

    return variantId;
  } catch (err) {
    logError((err as Error).message, {
      method: 'getVariantId',
    });
  }
};

const getSpreeProductsBySlug = (
  productVariants: any,
): { [index: string]: any } =>
  Object.entries(productVariants).reduce(
    (acc: Record<string, any>, value): object => {
      const prodIdStr = value[0];
      const productData: any = value[1];
      acc[productData.slug] = {
        ...productData,
        productId: Number(prodIdStr),
      };
      return acc;
    },
    {},
  );

const getCombinedContenfulSpreeProduct = (
  contentfulProduct: any,
  spreeProductsBySlug: any,
  allReviews: ContentfulReview[],
) => {
  const {
    product,
    pageSections,
    consultAvailable,
    consultAvailableText,
    disclaimer,
    comparisonTable,
    heroDescriptionHeader,
    heroDescriptionText,
    heroMeasuresText,
    heroMeasuresIcon,
    heroPurchaseDetailsCallout,
    heroPurchaseDetailsBottomCallout,
    heroSliderImages,
    heroMeasuresContentSection,
    heroModalTitle,
    heroModalButtonText,
    hideSubscription,
    preSelectedSubscription,
    heroSubscriptionFaqUrl,
    heroDescriptionCallout,
    productSchema,
    faqSchema,
    metaTitle,
    metaDescription,
    metaKeywords,
    canonicalUrl,
    screener,
  } = contentfulProduct;
  if (!product) return; // necessary for preview envs to work
  const { category } = product;

  // Spree Slugs must match Contentful Slugs
  const spreeProduct = spreeProductsBySlug[product.slug];

  if (!spreeProduct) {
    return;
  }

  // combine the Contentful Product data with Spree product data
  return {
    ...product,
    price: spreeProduct.price,
    productId: spreeProduct.productId,
    sku: spreeProduct.sku,
    slug: spreeProduct.slug,
    upc: spreeProduct.upc,
    canonicalUrl: canonicalUrl,
    variantId: spreeProduct.variantId,
    disclaimer: disclaimer,
    comparisonTable: comparisonTable,
    screener: screener,
    name: spreeProduct.name,
    category: category && category.title ? category.title : '',
    subscriptionVariants: spreeProduct.subscriptionVariants,
    reviewData: allReviews.find(
      (rev) => Number(rev.product_page_id) === spreeProduct.productId,
    ),
    metaTitle: metaTitle || spreeProduct.metaTitle,
    metaDescription:
      metaDescription?.metaDescription || spreeProduct.metaDescription,
    metaKeywords: metaKeywords?.metaKeywords || spreeProduct.metaKeywords,
    productSchema,
    faqSchema,
    pageSections: pageSections,
    heroSection: {
      heroDescriptionHeader,
      heroDescriptionText: heroDescriptionText?.heroDescriptionText,
      consultAvailable,
      consultAvailableText,
      heroMeasuresText,
      heroMeasuresIcon,
      heroPurchaseDetailsCallout,
      heroPurchaseDetailsBottomCallout,
      heroMeasuresContentSection,
      heroModalTitle,
      heroModalButtonText,
      heroSliderImages,
      heroDescriptionCallout,
    },
    hideSubscription,
    preSelectedSubscription,
    heroSubscriptionFaqUrl,
    promotionable: spreeProduct.promotionable,
    maxQuantity: spreeProduct.maxQuantity,
  };
};

/*
 * map product data fetched from contentful to results in Spree for PDP
 * TODO: this is only being used for the PDP so its specific to the PDP
 * its looking `allContentfulPdpPage` in the result parameter
 */
const getAllPages = (
  contentfulPdpPage: PromoDataResult,
  productVariants: ProductVariants,
  reviews: Reviews,
) => {
  const spreeProductsBySlug = getSpreeProductsBySlug(productVariants);
  const pdpPages: PageProductPromo[] = [];

  contentfulPdpPage?.nodes?.forEach((node) => {
    pdpPages.push(node);
  });

  // use slug in Contentful Product to marry with the Spree Product
  const allPages = pdpPages.map((contentfulProduct) =>
    getCombinedContenfulSpreeProduct(
      contentfulProduct,
      spreeProductsBySlug,
      reviews.nodes,
    ),
  );

  return allPages;
};

const getUpdatedProductBySlug = (
  contentfulPdpPage: PromoDataResult,
  productVariants: ProductVariants,
  reviews: Reviews,
  productsBySlug: ProductsBySlugType,
) => {
  const newProductBySlug = { ...productsBySlug };
  const contentfulDataBySlug: any = [];
  const allPages = getAllPages(contentfulPdpPage, productVariants, reviews);

  allPages.forEach((page: any) => {
    if (page?.screener && page?.disclaimer) {
      contentfulDataBySlug.push({
        slug: page.slug,
        screener: page.screener,
        disclaimer: page.disclaimer,
      });
    }
  });

  // Add the screener and disclaimer contentful nodes to the products to be used
  Object.keys(newProductBySlug).forEach((key: string) => {
    const screenerProduct = contentfulDataBySlug.find(
      (product: any) => product.slug === key,
    );

    if (screenerProduct) {
      newProductBySlug[key].screener = screenerProduct.screener;
      newProductBySlug[key].disclaimer = screenerProduct.disclaimer;
    }
  });

  return newProductBySlug;
};

const isFullUrl = (url?: string): boolean => url?.startsWith('http') ?? false;

const getCSSPropertyValue = (
  el: HTMLDivElement | null,
  propertyName: string,
) => {
  if (!isBrowser() || !el) return '';

  return window.getComputedStyle(el, null).getPropertyValue(propertyName);
};

export {
  addSuperscript,
  capitalize,
  filterUniqueByCategory,
  findAllByKey,
  formatPrice,
  generateKey,
  getSkuKey,
  getSpreeProductsBySlug,
  getUpdatedProductBySlug,
  getVariantId,
  hashSha256,
  isBrowser,
  isFullUrl,
  logError,
  numericDate,
  scrollToId,
  timeAgo,
  toKebabCase,
  trackIterable,
  useClickOff,
  useDisableBodyScroll,
  getCSSPropertyValue,
};
