import { mixpanel } from './src/utils/trackers';

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const { worker } = await import('./src/testUtils/mocks/msw/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

export const onClientEntry = () => {
  // initialize mixpanel globally
  mixpanel.init();

  enableMocking();
};

// work-around for gatsby issue -- fix scroll restoration
// see https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1658071105
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = 'manual';
  const currentPosition = getSavedScrollPosition(location, location.key);

  // if there's a hash in the URL, don't scroll to the saved position
  if (location.hash) return;

  // minimum timeout needs to be somewhat greater than zero the avoid map loading
  // interferance on park pages
  let timeout = 100;

  // use a longer timeout for longer pages so they can finish rendering first
  if (currentPosition && currentPosition.length > 1 && currentPosition[1]) {
    const y = currentPosition[1];
    if (y > 2 * window.innerHeight) {
      timeout = 750;
    }
  }
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, timeout);
  return false;
};

export { wrapRootElement } from './gatsby-shared';
