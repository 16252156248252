let API_ROOT: string,
  APP_ROOT: string,
  STORE_ROOT: string,
  RESULTS_ROOT: string;

const ENVIRONMENTS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  QA: 'qa',
  Q1: 'q1',
  Q2: 'q2',
  Q3: 'q3',
  Q4: 'q4',
  Q5: 'q5',
  Q6: 'q6',
  Q7: 'q7',
  Q8: 'q8',
  Q9: 'q9',
  Q10: 'q10',
  PREVIEW: 'preview',
  DEV: 'dev',
  LOCAL: 'local',
  TEST: 'test',
};

const PRECONNECT_LINKS = [
  'https://www.google-analytics.com',
  'https://d18p8z0ptb8qab.cloudfront.net',
  'https://aa.agkn.com',
  'https://cnv.event.prod.bidr.io',
  'https://everlywell.extole.io',
  'https://www.googletagmanager.com',
];

switch (process.env.REACT_APP_ENV) {
  case ENVIRONMENTS.PRODUCTION:
    API_ROOT = 'https://secure.everlywell.com/aapi';
    APP_ROOT = 'https://www.everlywell.com';
    STORE_ROOT = 'https://secure.everlywell.com';
    RESULTS_ROOT = 'https://results.everlywell.com';
    break;
  case ENVIRONMENTS.STAGING:
    API_ROOT = 'https://secure-staging.everlywell.com/aapi';
    APP_ROOT = 'https://staging.everlywell.com';
    STORE_ROOT = 'https://secure-staging.everlywell.com';
    RESULTS_ROOT = 'https://results-staging.everlywell.com';
    break;
  case ENVIRONMENTS.PREVIEW:
    API_ROOT = 'https://PREVIEWURL.secure-preview.everlywell.com/aapi';
    APP_ROOT = 'https://PREVIEWURL.public-preview.everlywell.com';
    STORE_ROOT = 'https://PREVIEWURL.secure-preview.everlywell.com';
    RESULTS_ROOT = 'https://PREVIEWURL.results-preview.everlywell.com';
    break;
  case ENVIRONMENTS.Q1:
    API_ROOT = 'https://secure-q1.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q1.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q1.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q1.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q2:
    API_ROOT = 'https://secure-q2.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q2.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q2.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q2.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q3:
    API_ROOT = 'https://secure-q3.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q3.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q3.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q3.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q4:
    API_ROOT = 'https://secure-q4.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q4.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q4.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q4.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q5:
    API_ROOT = 'https://secure-q5.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q5.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q5.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q5.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q6:
    API_ROOT = 'https://secure-q6.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q6.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q6.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q6.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q7:
    API_ROOT = 'https://secure-q7.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q7.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q7.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q7.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q8:
    API_ROOT = 'https://secure-q8.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q8.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q8.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q8.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q9:
    API_ROOT = 'https://secure-q9.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q9.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q9.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q9.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.Q10:
    API_ROOT = 'https://secure-q10.pub.everlyhealth.dev/aapi';
    APP_ROOT = 'https://q10.pub.everlyhealth.dev';
    STORE_ROOT = 'https://secure-q10.pub.everlyhealth.dev';
    RESULTS_ROOT = 'https://results-q10.pub.everlyhealth.dev';
    break;
  case ENVIRONMENTS.QA:
    API_ROOT = 'https://secure-qa.everlywell.com/aapi';
    APP_ROOT = 'https://qa.everlywell.com';
    STORE_ROOT = 'https://secure-qa.everlywell.com';
    RESULTS_ROOT = 'https://results-qa.everlywell.com';
    break;
  case ENVIRONMENTS.DEV:
    API_ROOT = 'https://secure-dev.everlywell.com/aapi';
    APP_ROOT = 'https://dev.everlywell.com';
    STORE_ROOT = 'https://secure-dev.everlywell.com';
    RESULTS_ROOT = 'https://results-dev.everlywell.com';
    break;
  case ENVIRONMENTS.LOCAL:
    API_ROOT = process.env.API_ROOT || 'http://localhost:3000/aapi';
    APP_ROOT = process.env.PUBLIC_APP_ROOT || 'http://localhost:8080';
    STORE_ROOT = process.env.API_ROOT || 'http://localhost:3000';
    RESULTS_ROOT = 'http://localhost:3001';
    break;
  default:
    API_ROOT = 'https://secure-qa.everlywell.com/aapi';
    APP_ROOT = 'http://localhost:8080';
    STORE_ROOT = 'https://secure-qa.everlywell.com';
    RESULTS_ROOT = 'https://results-qa.everlywell.com';
}

const CONTENTFUL_ROOT = 'https://cdn.contentful.com:443';

if (process.env.NODE_ENV === ENVIRONMENTS.TEST) {
  API_ROOT = 'https://www.everlywell.com/aapi';
  APP_ROOT = 'https://www.everlywell.com';
  STORE_ROOT = 'https://www.everlywell.com';
  RESULTS_ROOT = 'https://results.everlywell.com';
}

export {
  API_ROOT,
  APP_ROOT,
  CONTENTFUL_ROOT,
  ENVIRONMENTS,
  PRECONNECT_LINKS,
  RESULTS_ROOT,
  STORE_ROOT,
};
