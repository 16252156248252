import createDOMPurify from 'dompurify';

import { isBrowser } from './helpers';

/**
 * equivalent of $.remove
 * removes specified HTML element from DOM
 * @param { HTML Element } element HTML to remove
 */
const remove = (element: Element) => {
  if (element && element.parentNode) {
    element.parentNode.removeChild(element);
  }
};

// show Flash Message
const showFlash = (classes: string, text: string) => {
  const alertSection = document.querySelector('#alert-flash');
  const ewHeader = document.querySelector('#app') as HTMLDivElement;

  if (alertSection) {
    remove(alertSection);
  }

  const flashDiv = document.createElement('div');
  flashDiv.id = 'alert-flash';
  flashDiv.classList.add(classes);
  flashDiv.textContent = text;
  flashDiv.setAttribute('aria-live', 'assertive');
  flashDiv.setAttribute('aria-atomic', 'true');

  if (!ewHeader || !ewHeader.parentNode) return null;
  ewHeader.parentNode.insertBefore(flashDiv, ewHeader.nextElementSibling);
};

type SanitizeOptions = {
  ALLOWED_TAGS?: string[]; // set tag whitelist
  ALLOWED_ATTR?: string[]; // set attribute whitelist
};

export const DEFAULT_SANITIZE_OPTIONS: SanitizeOptions = {
  ALLOWED_TAGS: [
    'p',
    'b',
    'i',
    'em',
    'strong',
    'ol',
    'li',
    'a',
    'sup',
    'br',
    'hr',
    'h1',
    'h2',
    'h3',
    'h4',
    'ol',
    'ul',
    'table',
    'tbody',
    'th',
    'tr',
    'td',
    'thead',
  ],
  // don't allow attributes by default
  ALLOWED_ATTR: ['href', 'target', 'data-*'],
};

/**
 * sanitize an untrusted HTML string so it only contains allowed HTML
 *
 * returns a sanitized HTML string
 * @param dirty a string containing untrusted HTML
 * @param options an object containing the allowed tags and attributes
 * (refer to https://github.com/cure53/DOMPurify/ for full options)
 */
const sanitize = (dirty: string, options?: SanitizeOptions) => {
  if (isBrowser()) {
    const DOMPurify = createDOMPurify(window);
    return DOMPurify.sanitize(dirty, {
      ...DEFAULT_SANITIZE_OPTIONS,
      ...options,
    });
  }

  return dirty;
};

export { remove, sanitize, showFlash };
